import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { bscTokens } from '@pancakeswap/tokens'
import { IsCakeVault, IsLocked, getLivePoolsConfig } from '@pancakeswap/pools'
import { ChainId } from '@pancakeswap/chains'

export const vaultPoolConfig = {
  ...(
    getLivePoolsConfig(ChainId.PULSE)!.reduce((prev: any, pool: any) => {
      const isLocked = IsLocked(pool.vaultKey)
      const isCakeVault = IsCakeVault(pool.vaultKey)
      return {
        ...prev,
        [pool.vaultKey]: {
          name: isLocked ? `Stake ${pool.stakingToken.symbol}` : `Flexible ${pool.stakingToken.symbol}`,
          description: isCakeVault ? "Stake, Earn – And more!" : `Stake ${pool.stakingToken.symbol}, Earn BBC`,
          autoCompoundFrequency: 5000,
          gasLimit: 600000,
          tokenImage: {
            primarySrc: `/assets/symbols/${pool.stakingToken.symbol.toLowerCase()}.png`,
            secondarySrc: isCakeVault ? `/assets/icons/${isLocked ? 'locked.png' : 'autorenew.svg'}` : `/assets/symbols/bbc.png`,
          },
        },
      }
    }, {} as any)
  )
}

// export const vaultPoolConfig = {
//   [VaultKey.CakeVaultV1]: {
//     name: <Trans>Auto CAKE</Trans>,
//     description: <Trans>Automatic restaking</Trans>,
//     autoCompoundFrequency: 5000,
//     gasLimit: 380000n,
//     tokenImage: {
//       primarySrc: `/images/tokens/${bscTokens.cake.address}.png`,
//       secondarySrc: '/images/autorenew.svg',
//     },
//   },
//   [VaultKey.CakeVault]: {
//     name: <Trans>Stake CAKE</Trans>,
//     description: <Trans>Stake, Earn – And more!</Trans>,
//     autoCompoundFrequency: 5000,
//     gasLimit: 1100000n,
//     tokenImage: {
//       primarySrc: `/images/tokens/${bscTokens.cake.address}.png`,
//       secondarySrc: '/images/autorenew.svg',
//     },
//   },
//   [VaultKey.IfoPool]: {
//     name: 'IFO CAKE',
//     description: <Trans>Stake CAKE to participate in IFOs</Trans>,
//     autoCompoundFrequency: 1,
//     gasLimit: 500000n,
//     tokenImage: {
//       primarySrc: `/images/tokens/${bscTokens.cake.address}.png`,
//       secondarySrc: `/images/ifo-pool-icon.svg`,
//     },
//   },
// } as const

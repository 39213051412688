/* eslint-disable no-param-reassign */
import { gql, GraphQLClient } from 'graphql-request'
import { getMultiChainQueryEndPointWithStableSwapPulseX, subgraphTokenSymbol } from 'state/info/constant'
import { PoolData } from 'state/info/types'
import { safeGetAddress } from 'utils'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getLpFeesAndApr } from 'utils/getLpFeesAndApr'
import { getAmountChange, getPercentChange } from 'views/Info/utils/infoDataHelpers'
import axios from 'axios'
import { STABLESWAP_SUBGRAPH_CLIENT } from 'config/constants/endpoints'
import {
  MultiChainName,
  getMultiChainQueryEndPointWithStableSwap
} from '../../constant'

// const API_URL = "http://localhost:9875"
const API_URL = "https://poolsinfoapi.9inch.io"


interface PoolFields {
  id: string
  reserve0: string
  reserve1: string
  reserve2?: string
  reserveUSD: string
  volumeUSD: string
  volumeOutUSD?: string
  token0Price: string
  token1Price: string
  token2Price?: string
  timestamp: number
  token0?: {
    id: string
    symbol: string
    name: string
  }
  token1?: {
    id: string
    symbol: string
    name: string
  }
  token2?: {
    id: string
    symbol: string
    name: string
  }
}

export interface FormattedPoolFields
  extends Omit<
    PoolFields,
    'volumeUSD' | 'reserveUSD' | 'reserve0' | 'reserve1' | 'reserve2' | 'token0Price' | 'token1Price' | 'token2Price' | 'volumeOutUSD'
  > {
  volumeUSD: number
  reserveUSD: number
  reserve0: number
  reserve1: number
  reserve2?: number
  token0Price: number
  token1Price: number
  token2Price?: number
  volumeOutUSD?: number
}

interface PoolsQueryResponse {
  now: PoolFields[]
  oneDayAgo: PoolFields[]
  twoDaysAgo: PoolFields[]
  oneWeekAgo: PoolFields[]
  twoWeeksAgo: PoolFields[]
}

/**
 * Data for displaying pool tables (on multiple pages, used throughout the site)
 * Note: Don't try to refactor it to use variables, server throws error if blocks passed as undefined variable
 * only works if its hard-coded into query string
 */
const POOL_AT_BLOCK = (chainName: MultiChainName, block: number | null, pools: string[], isStableSwap: boolean = false) => {
  const blockString = block ? `block: {number: ${block}}` : ``
  const addressesString = `["${pools.join('","')}"]`

  if (isStableSwap)
    return `pairs(
      ${pools.length ? `where: { id_in: ${addressesString} }` : ''}    
      ${blockString}
      ${pools.length === 0 ? `
        orderBy: reserveETH
        orderDirection: desc
      ` : ''}
    ) {
      id
      reserve0
      reserve1
      reserve2
      reserveUSD
      volumeUSD
      volumeOutUSD
      token0Price
      token1Price
      token2Price
      timestamp
      token0 {
        id
        symbol
        name
      }
      token1 {
        id
        symbol
        name
      }
      token2 {
        id
        symbol
        name
      }
    }`
  return `pairs(
    ${pools.length ? `where: { id_in: ${addressesString} }` : ''}    
    ${blockString}
    ${pools.length === 0 ? `
        orderBy: trackedReserveETH
        orderDirection: desc
    ` : ''}
  ) {
    id
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    token0Price
    token1Price
    timestamp
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
  }`
}

export const fetchPoolData = async (
  currentBlock: number,
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  poolAddresses: string[],
  chainName: MultiChainName = 'PULSE',
  isStableSwap: boolean = false,
  fetchFromSubgraph: boolean = false
) => {
  try {

    if (fetchFromSubgraph) {
      const query = gql`
        query pools {
          now: ${POOL_AT_BLOCK(chainName, currentBlock, poolAddresses, isStableSwap)}
          oneDayAgo: ${POOL_AT_BLOCK(chainName, block24h, poolAddresses, isStableSwap)}
          twoDaysAgo: ${POOL_AT_BLOCK(chainName, block48h, poolAddresses, isStableSwap)}
          oneWeekAgo: ${POOL_AT_BLOCK(chainName, block7d, poolAddresses, isStableSwap)}
          twoWeeksAgo: ${POOL_AT_BLOCK(chainName, block14d, poolAddresses, isStableSwap)}
        }
      `
      const data = await getMultiChainQueryEndPointWithStableSwap(chainName, isStableSwap).request<PoolsQueryResponse>(query)
      if (!data) {
        return { error: true }
      }

      return { data, error: false }
    }

    const resp = await axios.get(`${API_URL}/fetchtoppoolsv2`)
    const { data } = resp
    return { data: data.data, error: false }
  } catch (error) {
    console.error('Failed to fetch pool data', error)
    return { error: true }
  }
}



export const fetchDerivedUSDPricePerAddress = async (address, chainName: MultiChainName = 'PULSE', isStableSwap: boolean = false) => {
  try {
    const query = gql`
      query GetDerivedUSDPrice($address: String!) {
        tokens(where: { id: $address }) {
          derivedUSD
        }
      }
    `;
    // Assuming getMultiChainQueryEndPointWithStableSwap is a function to get the GraphQL endpoint
    let data;
    if (address.toLowerCase() === "0xdac17f958d2ee523a2206206994597c13d831ec7") {
      data = await getMultiChainQueryEndPointWithStableSwapPulseX(chainName, isStableSwap).request(query, { address: address.toLowerCase() });
    } else {
      data = await getMultiChainQueryEndPointWithStableSwap(chainName, isStableSwap).request(query, { address: address.toLowerCase() });
    }

    if (data.tokens && data.tokens.length > 0) {
      return { price: parseFloat(data.tokens[0].derivedUSD), error: false };
    }
    throw new Error(`No data found for token: ${address}`);

  } catch (error) {
    console.error(`Error fetching derived USD price for ${address}:`, error);
    return { error: true };
  }
}

// Transforms pools into "0xADDRESS: { ...PoolFields }" format and cast strings to numbers
export const parsePoolData = (pairs?: PoolFields[]) => {
  if (!pairs) {
    return {}
  }
  return pairs.reduce((accum: { [address: string]: FormattedPoolFields }, poolData) => {
    const { volumeUSD, reserveUSD, reserve0, reserve1, reserve2, token0Price, token1Price, token2Price, volumeOutUSD } = poolData
    accum[poolData.id.toLowerCase()] = {
      ...poolData,
      volumeUSD: parseFloat(volumeUSD),
      volumeOutUSD: volumeOutUSD && parseFloat(volumeOutUSD),
      reserveUSD: parseFloat(reserveUSD),
      reserve0: parseFloat(reserve0),
      reserve1: parseFloat(reserve1),
      reserve2: parseFloat(reserve2),
      token0Price: parseFloat(token0Price),
      token1Price: parseFloat(token1Price),
      token2Price: parseFloat(token2Price),
    }
    return accum
  }, {})
}

export const POOL_AT_BLOCK_API = async (
  chainName: MultiChainName,
  block: string | number,
  pools: string[],
  isStableSwap: boolean = false
): Promise<{ [address: string]: FormattedPoolFields }> => {
  if (isStableSwap) {

    try {

      const query = gql`
      query {
          pairs(first: 1000, block: { number: ${block} }, orderBy: reserveETH, orderDirection: desc) {
            id
            reserve0
            reserve1
            reserve2
            reserveUSD
            volumeUSD
            volumeOutUSD
            token0Price
            token1Price
            token2Price
            timestamp
            token0 {
              id
              symbol
              name
              decimals
            }
            token1 {
              id
              symbol
              name
              decimals
            }
            token2 {
              id
              symbol
              name
              decimals
            }
          }
        }
  `

      const infoStableSwapClient = new GraphQLClient(STABLESWAP_SUBGRAPH_CLIENT)

      const response = await infoStableSwapClient.request(query)

      if (response && response.pairs) {
        return parsePoolData(response.pairs);
      }

      return parsePoolData([]);

    } catch (error) {
      console.error('Failed to fetch pool data', error);
      return {};
    }


  } else {
    try {
      const response = await axios.post(`${API_URL}/fetchpooldata`, {
        chainName,
        pools,
        blockParam: block.toString(),
        isStableSwap,
      })

      const { data } = response

      if (data && data.data && data.data.pairs) {
        return parsePoolData(data.data.pairs);
      }

      return parsePoolData([]);





    } catch (error) {
      console.error('Failed to fetch pool data', error);
      return {};
    }
  }

};

export const fetchPoolDataNew = async (
  currentBlock: string,
  block24h: string,
  block48h: string,
  block7d: string,
  block14d: string,
  poolAddresses: string[],
  chainName: MultiChainName = 'PULSE',
  isStableSwap: boolean = false
) => {
  try {
    const [now, oneDayAgo, twoDaysAgo, oneWeekAgo, twoWeeksAgo] = await Promise.all([
      POOL_AT_BLOCK_API(chainName, currentBlock, poolAddresses, isStableSwap),
      POOL_AT_BLOCK_API(chainName, block24h, poolAddresses, isStableSwap),
      POOL_AT_BLOCK_API(chainName, block48h, poolAddresses, isStableSwap),
      POOL_AT_BLOCK_API(chainName, block7d, poolAddresses, isStableSwap),
      POOL_AT_BLOCK_API(chainName, block14d, poolAddresses, isStableSwap)
    ]);

    const convertToPoolFields = (formattedPools: { [address: string]: FormattedPoolFields }): PoolFields[] => {
      return Object.values(formattedPools).map(pool => ({
        ...pool,
        reserve0: pool.reserve0.toString(),
        reserve1: pool.reserve1.toString(),
        reserve2: pool.reserve2?.toString(),
        reserveUSD: pool.reserveUSD.toString(),
        volumeUSD: pool.volumeUSD.toString(),
        volumeOutUSD: pool.volumeOutUSD?.toString(),
        token0Price: pool.token0Price.toString(),
        token1Price: pool.token1Price.toString(),
        token2Price: pool.token2Price?.toString(),
      }));
    };

    const data: PoolsQueryResponse = {
      now: convertToPoolFields(now),
      oneDayAgo: convertToPoolFields(oneDayAgo),
      twoDaysAgo: convertToPoolFields(twoDaysAgo),
      oneWeekAgo: convertToPoolFields(oneWeekAgo),
      twoWeeksAgo: convertToPoolFields(twoWeeksAgo)
    };


    return { data, error: false };
  } catch (error) {
    console.error('Failed to fetch pool data', error);
    return { error: true };
  }
};

const retryWithDelay = (
  chainName: MultiChainName,
  poolAddresses: string[],
  isStableSwap: boolean,
  maxRetries: number,
  delay: number
) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(fetchAllPoolDataWithAddress(chainName, poolAddresses, isStableSwap, maxRetries, delay));
  }, delay);
});

export const fetchAllPoolDataWithAddress = async (
  chainName: MultiChainName,
  poolAddresses: string[],
  isStableSwap: boolean = false,
  maxRetries: number = 3,
  initialRetryDelay: number = 1000
) => {

  const _blocks = await fetchLatestBlocks()

  const [currentBlock, block24h, block48h, block7d, block14d] = _blocks ?? []

  const { data, error } = await fetchPoolDataNew(
    currentBlock.toString(),
    block24h.toString(),
    block48h.toString(),
    block7d.toString(),
    block14d.toString(),
    poolAddresses,
    chainName,
    isStableSwap
  );
  // const { data, error } = await fetchPoolDataNew(
  //   'latest',
  //   'block24h',
  //   'block48h',
  //   'block7d',
  //   'block14d',
  //   poolAddresses,
  //   chainName,
  //   isStableSwap
  // );

  const formattedPoolData = parsePoolData(data?.now);

  if (Object.keys(formattedPoolData).length === 0) {
    throw new Error('Pool data is empty');
  }

  const formattedPoolData24h = parsePoolData(data?.oneDayAgo);
  const formattedPoolData48h = parsePoolData(data?.twoDaysAgo);
  const formattedPoolData7d = parsePoolData(data?.oneWeekAgo);
  const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo);

  const addresses = Object.keys(formattedPoolData);

  // Calculate data and format
  const formatted = addresses.reduce((accum: { [address: string]: { data: PoolData } }, address) => {
    // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
    const current: FormattedPoolFields | undefined = formattedPoolData[address]
    const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[address]
    const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[address]
    const week: FormattedPoolFields | undefined = formattedPoolData7d[address]
    const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[address]

    const [volumeUSD, volumeUSDChange] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
    const volumeOutUSD = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, oneDay?.volumeOutUSD)
    const volumeOutUSDWeek = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, week?.volumeOutUSD)
    const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
      current?.volumeUSD,
      week?.volumeUSD,
      twoWeeks?.volumeUSD,
    )

    const volumeUSD24H = current?.volumeUSD - oneDay?.volumeUSD

    const liquidityUSD = current ? current.reserveUSD : 0

    const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)

    const liquidityToken0 = current ? current.reserve0 : 0
    const liquidityToken1 = current ? current.reserve1 : 0
    const liquidityToken2 = current ? current.reserve2 : 0
    const timestamp = current.timestamp ?? 0

    const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
      volumeUSD,
      volumeUSDWeek,
      liquidityUSD,
    )

    if (current) {
      accum[address] = {
        data: {
          address,
          token0: {
            address: current?.token0?.id ?? '',
            name: current?.token0?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token0?.id)] ?? current?.token0?.symbol ?? '',
          },
          token1: {
            address: current?.token1?.id ?? '',
            name: current?.token1?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token1?.id)] ?? current?.token1?.symbol ?? '',
          },
          token2: {
            address: current?.token2?.id ?? '',
            name: current?.token2?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token2?.id)] ?? current?.token2?.symbol ?? '',
          },
          timestamp,
          token0Price: current.token0Price,
          token1Price: current.token1Price,
          token2Price: current.token2Price,
          volumeUSD,
          volumeUSD24H,
          volumeUSDChange,
          volumeUSDWeek,
          volumeUSDChangeWeek,
          totalFees24h,
          totalFees7d,
          lpFees24h,
          lpFees7d,
          lpApr7d,
          liquidityUSD,
          liquidityUSDChange,
          liquidityToken0,
          liquidityToken1,
          liquidityToken2,
          volumeOutUSD,
          volumeOutUSDWeek,
        },
      }
    }

    return accum
  }, {})
  return formatted









  // const response = await axios.post(`${ API_URL } / pooldata`, {
  //   addresses,
  //   formattedPoolData,
  //   formattedPoolData24h,
  //   formattedPoolData48h,
  //   formattedPoolData7d,
  //   formattedPoolData14d,
  //   subgraphTokenSymbol,
  // });

  // return response.data;
};

// let lastError;
// let retryDelay = initialRetryDelay;

// for (let attempt = 0; attempt < maxRetries; attempt++) {
//   try {
//     return fetchAndProcessData();
//   } catch (error) {
//     lastError = error;
//     console.error(`Error fetching pool data(attempt ${ attempt + 1}/${maxRetries}): `, error);

//     if (attempt < maxRetries - 1) {
//       retryDelay *= 2;
//       return retryWithDelay(chainName, poolAddresses, isStableSwap, maxRetries - 1, retryDelay);
//     }
//   }
// }

// throw lastError || new Error(`Failed to fetch pool data after ${ maxRetries } attempts`);


export const fetchAllPoolDataWithAddressOld = async (
  chainName: MultiChainName,
  poolAddresses: string[],
  isStableSwap: boolean = false,
  fetchFromSubgraph: boolean = false
) => {
  const blocks = await fetchLatestBlocks()
  const [currentBlock, block24h, block48h, block7d, block14d] = blocks ?? []

  const { data } = await fetchPoolData(
    currentBlock,
    block24h,
    block48h,
    block7d,
    block14d,
    poolAddresses,
    chainName,
    isStableSwap,
    fetchFromSubgraph
  )

  const formattedPoolData = parsePoolData(data?.now)
  const formattedPoolData24h = parsePoolData(data?.oneDayAgo)
  const formattedPoolData48h = parsePoolData(data?.twoDaysAgo)
  const formattedPoolData7d = parsePoolData(data?.oneWeekAgo)
  const formattedPoolData14d = parsePoolData(data?.twoWeeksAgo)

  const addresses = Object.keys(formattedPoolData)

  // Calculate data and format
  const formatted = addresses.reduce((accum: { [address: string]: { data: PoolData } }, address) => {
    // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
    const current: FormattedPoolFields | undefined = formattedPoolData[address]
    const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[address]
    const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[address]
    const week: FormattedPoolFields | undefined = formattedPoolData7d[address]
    const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[address]

    const [volumeUSD, volumeUSDChange] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
    const volumeOutUSD = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, oneDay?.volumeOutUSD)
    const volumeOutUSDWeek = current?.volumeOutUSD && getAmountChange(current?.volumeOutUSD, week?.volumeOutUSD)
    const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
      current?.volumeUSD,
      week?.volumeUSD,
      twoWeeks?.volumeUSD,
    )

    const volumeUSD24H = current?.volumeUSD - oneDay?.volumeUSD

    const liquidityUSD = current ? current.reserveUSD : 0

    const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)

    const liquidityToken0 = current ? current.reserve0 : 0
    const liquidityToken1 = current ? current.reserve1 : 0
    const liquidityToken2 = current ? current.reserve2 : 0
    const timestamp = current.timestamp ?? 0

    const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
      volumeUSD,
      volumeUSDWeek,
      liquidityUSD,
    )

    if (current) {
      accum[address] = {
        data: {
          address,
          token0: {
            address: current?.token0?.id ?? '',
            name: current?.token0?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token0?.id)] ?? current?.token0?.symbol ?? '',
          },
          token1: {
            address: current?.token1?.id ?? '',
            name: current?.token1?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token1?.id)] ?? current?.token1?.symbol ?? '',
          },
          token2: {
            address: current?.token2?.id ?? '',
            name: current?.token2?.name ?? '',
            symbol: subgraphTokenSymbol[safeGetAddress(current?.token2?.id)] ?? current?.token2?.symbol ?? '',
          },
          timestamp,
          token0Price: current.token0Price,
          token1Price: current.token1Price,
          token2Price: current.token2Price,
          volumeUSD,
          volumeUSD24H,
          volumeUSDChange,
          volumeUSDWeek,
          volumeUSDChangeWeek,
          totalFees24h,
          totalFees7d,
          lpFees24h,
          lpFees7d,
          lpApr7d,
          liquidityUSD,
          liquidityUSDChange,
          liquidityToken0,
          liquidityToken1,
          liquidityToken2,
          volumeOutUSD,
          volumeOutUSDWeek,
        },
      }
    }

    return accum
  }, {})
  return formatted
}

export const fetchLatestBlocks = async (): Promise<number[]> => {
  try {
    const response = await axios.get(`${API_URL}/latestblocks`);

    // if (!response.ok) {
    //   throw new Error(`HTTP error! status: ${response.status}`);
    // }

    const blocks = response.data
    return blocks;
  } catch (error) {
    const now = Math.floor(Date.now() / 1000)
    const query = gql`
      query blocks {
        now: transactions(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: "${now}" }) {
          number: block
        }
        block24h: transactions(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: "${now - 86400}" }) {
          number: block
        }
        block48h: transactions(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: "${now - 86400 * 2}" }) {
          number: block
        }
        block1w: transactions(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: "${now - 86400 * 7}" }) {
          number: block
        }
        block2w: transactions(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lte: "${now - 86400 * 14}" }) {
          number: block
        }
      }
    `
    const data = await getMultiChainQueryEndPointWithStableSwap("PULSE").request<any>(query)
    if (data) {
      return [data.now[0].number, data.block24h[0].number, data.block48h[0].number, data.block1w[0].number, data.block2w[0].number]
    }
    console.error('Failed to fetch top pool addresses', error);
    return [];
  }
}

// export const fetchTopPoolAddressesNew = async (chainName: MultiChainName): Promise<string[]> => {
//   try {
//     const response = await fetch(`${API_URL}/fetchtoppools?count=10000`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//     const pools = await response.json()
//     return pools;


//   } catch (error) {
//     console.error('Failed to fetch top pool addresses', error);
//     return [];
//   }
// }

export const fetchAllPoolData = async (chainName: MultiChainName, isStableSwap: boolean = false) => {
  return isStableSwap ? fetchAllPoolDataWithAddress(chainName, [], isStableSwap) :
    fetchAllPoolDataWithAddress(chainName, [], isStableSwap)
}






import { CheckmarkCircleIcon, Flex, RefreshIcon, LinkExternal, ErrorIcon } from '@pancakeswap/uikit'
import { styled } from 'styled-components'
import { useAppDispatch } from 'state'
import { useTranslation } from '@pancakeswap/localization'
import { TransactionDetails } from 'state/transactions/reducer'
import { pickFarmTransactionTx } from 'state/global/actions'
import { TransactionType, FarmTransactionStatus } from 'state/transactions/actions'
import { getBlockExploreLink } from 'utils'
import { getTokenLogoURLWithSymbol } from 'utils/getTokenLogoURL'

interface TransactionRowProps {
  txn: TransactionDetails
  chainId: number
  type: TransactionType
  onDismiss: () => void
}

const Card = styled.div`
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  flex: 1;
  text-transform: capitalize;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
  }
`;

const SwapDetails = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
  word-break: break-word;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
  }
`;

const Timestamp = styled.div`
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 10px;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 12px;
  }
`;

const CurrencyIcons = styled.div`
  display: flex;
  gap: 4px;
`;

const CurrencyIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 20px;
    height: 20px;
  }
`;

const StyledLinkExternal = styled(LinkExternal)`
  font-size: 10px;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 12px;
  }
`;

const renderIcon = (txn: TransactionDetails) => {
  const { receipt, nonBscFarm } = txn
  if (!txn.receipt || nonBscFarm?.status === FarmTransactionStatus.PENDING) {
    return <RefreshIcon spin width="24px" />
  }

  const isFarmStatusSuccess = nonBscFarm ? nonBscFarm.status === FarmTransactionStatus.SUCCESS : true
  return (receipt?.status === 1 && isFarmStatusSuccess) || typeof receipt?.status === 'undefined' ? (
    <CheckmarkCircleIcon color="success" width="24px" />
  ) : (
    <ErrorIcon color="failure" width="24px" />
  )
}

const TransactionRow: React.FC<React.PropsWithChildren<TransactionRowProps>> = ({ txn, chainId, type, onDismiss }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onClickTransaction = () => {
    if (type === 'non-bsc-farm') {
      onDismiss()
      dispatch(pickFarmTransactionTx({ tx: txn.hash, chainId }))
    } else {
      const url = getBlockExploreLink(txn.hash, 'transaction', chainId)
      window.open(url, '_blank', 'noopener noreferrer')
    }
  }

  if (!txn) {
    return null
  }

  const timestamp = txn.confirmedTime ? new Date(txn.confirmedTime).toLocaleString() : ""
  let token0Logo = ""
  let token1Logo = ""
  let txTitle = String(txn.type).replaceAll("-", " ")
  const explorerLink = getBlockExploreLink(txn.hash, 'transaction', chainId)

  if (txn.type === 'swap' || txn.type === 'dca-submission') {
    if (txn.type === 'dca-submission')
      txTitle = "DCA"
    else
      txTitle = "Swap"
    token0Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.inputSymbol.toString().toLowerCase())
    token1Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.outputSymbol.toString().toLowerCase())
  } else if (txn.type.endsWith('-liquidity')) {
    if (txn.type === 'add-liquidity')
      txTitle = "Add Liquidity"
    else if (txn.type === 'remove-liquidity')
      txTitle = "Remove Liquidity"
    token0Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.symbolA.toString()).toLowerCase()
    token1Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.symbolB.toString()).toLowerCase()
  } else if (txn.type.endsWith('-liquidity-v3')) {
    if (txn.type === 'add-liquidity-v3')
      txTitle = "Add Liquidity V3"
    else if (txn.type === 'increase-liquidity-v3')
      txTitle = "Increase Liquidity V3"
    else if (txn.type === 'remove-liquidity-v3')
      txTitle = "Remove Liquidity V3"
    token0Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.baseSymbol.toString()).toLowerCase()
    token1Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.quoteSymbol.toString()).toLowerCase()
  } else if (txn.type === 'collect-fee') {
    txTitle = "Collect Fee"
    token0Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.symbol0.toString()).toLowerCase()
    token1Logo = getTokenLogoURLWithSymbol(txn.translatableSummary?.data.symbol1.toString()).toLowerCase()
  } else if (txn.type === 'limit-order-submission') {
    txTitle = "Open Order"
  } else if (txn.type === 'limit-order-cancellation') {
    txTitle = "Cancel Order"
  } else if (txn.type === 'limit-order-approval') {
    txTitle = "Approve Order"
  }

  return (
    <Card>
      <Header>
        {renderIcon(txn)}
        <Title>{txTitle}</Title>
        <CurrencyIcons>
          { !!token0Logo && <CurrencyIcon src={token0Logo} /> }
          { !!token1Logo && <CurrencyIcon src={token1Logo} /> }
        </CurrencyIcons>
      </Header>

      <SwapDetails>
        {txn.translatableSummary
          ? t(txn.translatableSummary.text, txn.translatableSummary.data)
          : txn.summary ?? txn.hash}
      </SwapDetails>
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Timestamp>{timestamp}</Timestamp>
        <StyledLinkExternal href={explorerLink} color='white' bold={false} small>
          View on Explorer
        </StyledLinkExternal>
      </Flex>
    </Card>
  )
}

export default TransactionRow
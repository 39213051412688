import { ChainId } from "../chains"
import config from "../../pulse.main.config.json"
import _DEFAULT_TOKEN_LIST from '../../apps/web/src/config/constants/tokenLists/nineinch-default.tokenlist.json'
import _UNSUPPORTED_TOKEN_LIST from '../../apps/web/src/config/constants/tokenLists/nineinch-unsupported.tokenlist.json'
import _WARNING_TOKEN_LIST from '../../apps/web/src/config/constants/tokenLists/nineinch-warning.tokenlist.json'
import _ONRAMP_TOKEN_LIST from '../../apps/web/src/config/constants/tokenLists/nineinch-supported-onramp-currency-list.json'

export default {
    [ChainId.PULSE]: config,
}

export const DEFAULT_TOKEN_LIST = _DEFAULT_TOKEN_LIST
export const UNSUPPORTED_TOKEN_LIST = _UNSUPPORTED_TOKEN_LIST
export const WARNING_TOKEN_LIST = _WARNING_TOKEN_LIST
export const ONRAMP_TOKEN_LIST = _ONRAMP_TOKEN_LIST

// export const DEFAULT_TOKENS: any = _DEFAULT_TOKEN_LIST.tokens.reduce((tokens, token) => {
//     return {
//         ...tokens,
//         [token.chainId]: {
//             ...tokens[token.chainId],
//             [token.name]: token,
//         }
//     }
// }, {})
import { gql } from 'graphql-request'
import { useEffect, useMemo, useState } from 'react'
import { infoClient, infoClientWithChain } from 'utils/graphql'
import { getDeltaTimestamps } from 'utils/getDeltaTimestamps'
import { useBlocksFromTimestamps } from 'views/Info/hooks/useBlocksFromTimestamps'
import { ChainId } from '@pancakeswap/chains'
import { ethers } from 'ethers'
import useSWRImmutable from 'swr/immutable'

export interface BnbPrices {
  current: number
  oneDay: number
  twoDay: number
  week: number
}

const BNB_PRICES = gql`
  query prices($block24: Int!, $block48: Int!, $blockWeek: Int!) {
    current: bundle(id: "1") {
      bnbPrice
    }
    oneDay: bundle(id: "1", block: { number: $block24 }) {
      bnbPrice
    }
    twoDay: bundle(id: "1", block: { number: $block48 }) {
      bnbPrice
    }
    oneWeek: bundle(id: "1", block: { number: $blockWeek }) {
      bnbPrice
    }
  }
`

const QUERY_PRICES = gql`
  query prices($ADDRESSES: [ID!]!) {
    bundle(id: "1") {
      price:ethPrice
    }
    tokens(where: {id_in: $ADDRESSES}) {
      id
      price:derivedUSD
    }
  }
`

interface PricesResponse {
  current: {
    bnbPrice: string
  }
  oneDay: {
    bnbPrice: string
  }
  twoDay: {
    bnbPrice: string
  }
  oneWeek: {
    bnbPrice: string
  }
}

const fetchBnbPrices = async (
  block24: number,
  block48: number,
  blockWeek: number,
): Promise<{ bnbPrices: BnbPrices | undefined; error: boolean }> => {
  try {
    const data = await infoClient.request<PricesResponse>(BNB_PRICES, {
      block24,
      block48,
      blockWeek,
    })
    return {
      error: false,
      bnbPrices: {
        current: parseFloat(data.current?.bnbPrice ?? '0'),
        oneDay: parseFloat(data.oneDay?.bnbPrice ?? '0'),
        twoDay: parseFloat(data.twoDay?.bnbPrice ?? '0'),
        week: parseFloat(data.oneWeek?.bnbPrice ?? '0'),
      },
    }
  } catch (error) {
    console.error('Failed to fetch BNB prices', error)
    return {
      error: true,
      bnbPrices: undefined,
    }
  }
}

/**
 * Returns BNB prices at current, 24h, 48h, and 7d intervals
 */
export const useBnbPrices = (): BnbPrices | undefined => {
  const [prices, setPrices] = useState<BnbPrices | undefined>()
  const [error, setError] = useState(false)

  const [t24, t48, tWeek] = getDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48, tWeek])

  useEffect(() => {
    const fetch = async () => {
      const [block24, block48, blockWeek] = blocks
      const { bnbPrices, error: fetchError } = await fetchBnbPrices(block24.number, block48.number, blockWeek.number)
      if (fetchError) {
        setError(true)
      } else {
        setPrices(bnbPrices)
      }
    }
    if (!prices && !error && blocks && !blockError) {
      fetch()
    }
  }, [error, prices, blocks, blockError])

  return prices
}

export const fetchTokenPrices = async (chainId: ChainId, addresses: string[]) => {
  try {
    const data = await infoClientWithChain(chainId)?.request<any>(QUERY_PRICES, {
      ADDRESSES: addresses.map(addr => addr.toLowerCase()),
    })
    return (data?.tokens ?? []).reduce((prices, token) => {
      const address = addresses.find(addr => addr.toLowerCase()===token.id)
      return {
        ...prices,
        [address!]: Number(token.price)
      }
    }, {
      [ethers.constants.AddressZero]: Number(data.bundle.price)
    })
  } catch (error) {
    console.error('Failed to fetch BNB prices', error)
  }
  return {}
}

export const useTokenPrices = (chainId: ChainId, addresses: string[]): any => {
  const { data: prices } = useSWRImmutable(
    addresses.length ? [`prices/addresses/${addresses.length}`] : undefined, 
    () => fetchTokenPrices(chainId, addresses),
    {
      refreshInterval: 60_000,
      keepPreviousData: true,
      errorRetryCount: 3,
      errorRetryInterval: 3000,
    }
  )
  return prices
}
import { pulseTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../..'

const priceHelperLps: SerializedFarmConfig[] = [
    {
        pid: null,
        lpSymbol: 'ETH-DAI LP',
        lpAddress: '0x55E8ed3762E976d440234476ED0D5c2202Cb24FB',
        quoteToken: pulseTokens.weth,
        token: pulseTokens.dai,
        vaultPid: null,
      },
      {
        pid: null,
        lpSymbol: 'ETH-BBC LP',
        lpAddress: '0xed35E0e3472Fd645D8c4429721541Fd317493CE2',
        quoteToken: pulseTokens.weth,
        token: pulseTokens.cake,
        vaultPid: null,
      },
      {
        pid: null,
        lpSymbol: '9INCH-DAI LP',
        lpAddress: '0x31AcF819060AE711f63BD6b682640598E250C689',
        quoteToken: pulseTokens.dai,
        token: pulseTokens.nineinch,
        vaultPid: null,
      },
      {
        pid: null,
        lpSymbol: '9INCH-USDC LP',
        lpAddress: '0x6C5a0F22b459973A0305e2a565fc208a35A13850',
        quoteToken: pulseTokens.usdc,
        token: pulseTokens.nineinch,
        vaultPid: null,
      },
      {
        pid: null,
        lpSymbol: '9INCH-USDT LP',
        lpAddress: '0x5449a776797B55A4AaC0B4A76B2AC878BFF3D3e3',
        quoteToken: pulseTokens.usdt,
        token: pulseTokens.nineinch,
        vaultPid: null,
      },
  ].map((p) => ({
    ...p,
    token: p.token.serialize,
    quoteToken: p.quoteToken.serialize,
    lpAddress: getAddress(p.lpAddress),
  }))

export default priceHelperLps
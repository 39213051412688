import BigNumber from 'bignumber.js'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { createSelector } from '@reduxjs/toolkit'
import { IsLocked, Locked2Flexible, type VaultKey, VaultList, IsFlexible } from '@pancakeswap/pools'
import { State } from '../types'
import { transformPool, transformVault } from './helpers'
import { initialPoolVaultState } from './index'
import { getVaultPosition, VaultPosition } from '../../utils/cakePool'

const selectPoolsData = (state: State) => state.pools.data
const selectPoolData = (sousId) => (state: State) => state.pools.data.find((p) => p.sousId === sousId)
const selectUserDataLoaded = (state: State) => state.pools.userDataLoaded
const selectVault = (key: VaultKey) => (state: State) => state.pools[key] ?? initialPoolVaultState
const selectIfo = (state: State) => state.pools.ifo
const selectIfoUserCredit = (state: State) => state.pools.ifo.credit ?? BIG_ZERO

export const makePoolWithUserDataLoadingSelector = (sousId) =>
  createSelector([selectPoolData(sousId), selectUserDataLoaded], (pool, userDataLoaded) => {
    return { pool: transformPool(pool!), userDataLoaded }
  })

export const poolsWithUserDataLoadingSelector = createSelector(
  [selectPoolsData, selectUserDataLoaded],
  (pools, userDataLoaded) => {
    return { pools: pools.map(transformPool), userDataLoaded }
  },
)

export const makeVaultPoolByKey = (key) => createSelector([selectVault(key)], (vault) => transformVault(key, vault))
export const makeVaultPoolsByKeys = (keys: VaultKey[]) => createSelector(
  keys.map(key => selectVault(key)), 
  (...vaults) => vaults.map((vault, i) => transformVault(keys[i], vault))
)

const keys: VaultKey[] = VaultList.reduce((prev, key) => [...prev, key, Locked2Flexible(key)], [] as VaultKey[])

export const poolsWithVaultSelector = createSelector(
  [
    poolsWithUserDataLoadingSelector,
    makeVaultPoolsByKeys(keys),
  ],
  (poolsWithUserDataLoading, vaults) => {
    const { pools, userDataLoaded } = poolsWithUserDataLoading
    const withoutCakePool = pools.filter((pool) => !pool.vaultKey)
    const lockedVaults = keys.map((key, index) => {
      const _pool = pools.find((pool) => !pool.isFinished && pool.vaultKey===key && IsLocked(key))
      if(!_pool) return undefined
      return {
        ..._pool,
        ...vaults[index],
        vaultKey: key,
        userData: { ..._pool.userData, ...vaults[index].userData },
      }
    }).filter(Boolean)
    const flexibleVaults = keys.map((key, index) => {
      const _pool = pools.find((pool) => !pool.isFinished && pool.vaultKey===key && IsFlexible(key))
      if(!_pool) return undefined
      const lockedVaultPosition = getVaultPosition(vaults[index-1].userData!)
      const hasFlexibleSideSharesStaked = vaults[index].userData!.userShares.gt(0)
      if(lockedVaultPosition > VaultPosition.Flexible || hasFlexibleSideSharesStaked)
        return {
          ..._pool,
          ...vaults[index],
          vaultKey: key,
          userData: { ..._pool.userData, ...vaults[index].userData },
        }
      return undefined
    }).filter(Boolean)

    return { pools: [...[...lockedVaults, ...flexibleVaults].sort((pool1, pool2) => pool1!.sousId - pool2!.sousId), ...withoutCakePool], userDataLoaded }
  },
)

export const makeVaultPoolWithKeySelector = (vaultKey) =>
  createSelector(poolsWithVaultSelector, ({ pools }) => pools.find((p) => p!.vaultKey === vaultKey))

export const ifoCreditSelector = createSelector([selectIfoUserCredit], (ifoUserCredit) => {
  return new BigNumber(ifoUserCredit)
})

export const ifoCeilingSelector = createSelector([selectIfo], (ifoData) => {
  return new BigNumber(ifoData.ceiling)
})

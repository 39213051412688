import { TransitionGroup } from "react-transition-group";
import { styled } from "styled-components";
import { useMemo } from "react";
import { createPortal } from "react-dom";
import { Toast } from "./Toast";
import { ToastContainerProps } from "./types";
import getPortalRoot from "../../util/getPortalRoot";

const ZINDEX = 2147483647; 
const POSITION_DESKTOP = 80;  
const POSITION_DESKTOP_RIGHT = 16;  
const POSITION_MOBILE = 80;

const StyledToastContainer = styled.div`
  position: fixed;
  z-index: ${ZINDEX};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  top: ${POSITION_DESKTOP}px;
  right: ${POSITION_DESKTOP_RIGHT}px; 
  
  @media (max-width: 768px) {
    bottom: 54px;
    left: 4px;
    right: 4px;
    top: unset;
  }
  
  .enter,
  .appear {
    opacity: 0.01;
  }

  .enter.enter-active,
  .appear.appear-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .exit {
    opacity: 1;
  }

  .exit.exit-active {
    opacity: 0.01;
    transition: opacity 250ms ease-in;
  }
`;

export const ToastContainer: React.FC<React.PropsWithChildren<ToastContainerProps>> = ({
  toasts,
  onRemove,
  ttl = 3800,
  stackSpacing = 8,
}) => {
  const portal = useMemo(() => getPortalRoot("toast"), []);
  if (portal) {
    return createPortal(
      <StyledToastContainer>
        <TransitionGroup>
          {toasts.map((toast, index) => {
            const zIndex = ZINDEX;

            return (
              <Toast 
                key={toast.id} 
                toast={toast} 
                onRemove={onRemove} 
                ttl={ttl} 
                style={{ 
                  marginBottom: index !== toasts.length - 1 ? `${stackSpacing}px` : '0',
                  zIndex 
                }} 
              />
            );
          })}
        </TransitionGroup>
      </StyledToastContainer>,
      portal)
  }
  return null
};

import React, { useEffect, useMemo, useRef } from 'react'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import forEach from 'lodash/forEach'
import { useTranslation } from '@pancakeswap/localization'
import { usePublicClient } from 'wagmi'
import { ToastDescriptionWithTx } from 'components/Toast'
import { Box, Text, useToast } from '@pancakeswap/uikit'
import { FAST_INTERVAL } from 'config/constants'
import useSWRImmutable from 'swr/immutable'
// import {
//   BlockNotFoundError,
//   TransactionNotFoundError,
//   TransactionReceiptNotFoundError,
//   WaitForTransactionReceiptTimeoutError,
// } from 'viem'
import { retry, RetryableError } from 'state/multicall/retry'
import { useAppDispatch } from 'state'
import axios from 'axios'
import {
  finalizeTransaction,
  FarmTransactionStatus,
  NonBscFarmTransactionStep,
  MsgStatus,
  NonBscFarmStepType,
  showTransaction,
} from './actions'
import { useAllChainTransactions } from './hooks'
import { fetchCelerApi } from './fetchCelerApi'
import { TransactionDetails } from './reducer'

export function shouldCheck(
  fetchedTransactions: { [txHash: string]: TransactionDetails },
  tx: TransactionDetails,
): boolean {
  if (tx.receipt) return false
  return !fetchedTransactions[tx.hash]
}

const WaitIcon = (props) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.319 235.319" {...props}>
      <g id="SVGRepo_iconCarrier"> 
        <g> 
          <path d="m201.094,29.997c2.649-0.623 4.623-2.996 4.623-5.835v-18.162c0-3.313-2.687-6-6-6h-164.114c-3.313,0-6,2.687-6,6v18.163c0,2.839 1.974,5.212 4.623,5.835 1.812,32.314 18.594,61.928 45.682,80.076l11.324,7.586-11.324,7.586c-27.089,18.147-43.871,47.762-45.682,80.076-2.649,0.623-4.623,2.996-4.623,5.835v18.163c0,3.313 2.687,6 6,6h164.114c3.313,0 6-2.687 6-6v-18.163c0-2.839-1.974-5.212-4.623-5.835-1.812-32.314-18.594-61.928-45.683-80.076l-11.324-7.586 11.324-7.586c27.089-18.148 43.871-47.763 45.683-80.077zm-159.491-17.997h152.114v6.163h-152.114v-6.163zm152.114,211.319h-152.114v-6.163h152.114v6.163zm-63.749-110.644c-1.663,1.114-2.661,2.983-2.661,4.985s0.998,3.871 2.661,4.985l18.765,12.571c23.71,15.883 38.49,41.705 40.333,69.941h-142.812c1.843-28.235 16.623-54.057 40.333-69.941l18.765-12.571c1.663-1.114 2.661-2.983 2.661-4.985s-0.998-3.871-2.661-4.985l-18.765-12.571c-23.71-15.884-38.49-41.706-40.333-69.941h142.812c-1.843,28.236-16.623,54.057-40.333,69.941l-18.765,12.571z" />
          <path d="m133.307,82.66h-31.295c-2.487,0-4.717,1.535-5.605,3.858-0.888,2.324-0.25,4.955 1.604,6.613l15.647,14c1.139,1.019 2.57,1.528 4,1.528s2.862-0.509 4-1.528l15.647-14c1.854-1.659 2.492-4.29 1.604-6.613-0.885-2.323-3.115-3.858-5.602-3.858z" /> 
          <path d="m117.414,140.581l-15.218,9.775c-13.306,8.914-21.292,23.876-21.292,39.892h76.511c0-16.016-7.986-30.978-21.292-39.892l-15.218-9.775c-1.074-0.644-2.416-0.644-3.491,0z" /> 
        </g> 
      </g>
    </svg>
  );
};

export const Updater: React.FC<{ chainId: number }> = ({ chainId }) => {
  const provider = usePublicClient({ chainId })
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const transactions = useAllChainTransactions(chainId)

  const { toastError, toastSuccess, toastInfo } = useToast()

  const fetchedTransactions = useRef<{ [txHash: string]: TransactionDetails }>({})
  const submitTransactions = useRef<{ [txHash: string]: boolean }>({})
  const completeTransactions = useRef<{ [txHash: string]: boolean }>({})

  useEffect(() => {
    if (!chainId || !provider) return
        
    forEach(
      pickBy(transactions, (transaction) => shouldCheck(fetchedTransactions.current, transaction)),
      (transaction) => {
        const getTransaction = async () => {
          try {
            let txTitle = String(transaction.type).replaceAll("-", " ")
            if (transaction.type === 'swap') {
              txTitle = "Swap"
            } else if (transaction.type === 'approve') {
              txTitle = "Approve"
            } else if (transaction.type === 'add-liquidity') {
              txTitle = "Add Liquidity"
            } else if (transaction.type === 'remove-liquidity') {
              txTitle = "Remove Liquidity"
            } else if (transaction.type === 'add-liquidity-v3') {
              txTitle = "Add Liquidity V3"
            } else if (transaction.type === 'remove-liquidity-v3') {
              txTitle = "Remove Liquidity V3"
            } else if (transaction.type === 'limit-order-submission') {
              txTitle = "Open Order"
            } else if (transaction.type === 'limit-order-cancellation') {
              txTitle = "Cancel Order"
            }

            if (!transaction.isShown && !submitTransactions.current[transaction.hash]) {
              toastInfo(
                `${txTitle} Submitted`,
                <ToastDescriptionWithTx txHash={transaction.hash} txChainId={chainId}>{transaction.summary}</ToastDescriptionWithTx>,
                <WaitIcon fill="white" width="32px" />
              )
              dispatch(
                showTransaction({
                  chainId,
                  hash: transaction.hash,
                }),
              )
              merge(submitTransactions.current, { [transaction.hash]: true })
            }
            
            let receipt: any = await provider.getTransactionReceipt({ hash: transaction.hash }).catch(() => undefined)

            if (!receipt) {
              receipt = await provider.waitForTransactionReceipt({ hash: transaction.hash, timeout: 60_000 })
            }

            if (receipt?.status) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash: transaction.hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: Number(receipt.blockNumber),
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status === 'success' ? 1 : 0,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              )

              if (!completeTransactions.current[transaction.hash]) {
                if (receipt.status === 'success' && transaction.type === 'deploy-token') {
                  const DEPLOYED_TOKENS_API_URL = 'https://deployedtokensapi.9inch.io'
                  axios.post(`${DEPLOYED_TOKENS_API_URL}/insertToken`, {
                    ...transaction.translatableSummary.data,
                    tokenAddress: receipt.contractAddress,
                    deployTxHash: receipt.transactionHash
                  })
                }
                const toast = receipt.status === 'success' ? toastSuccess : toastError            
                toast(
                  t("%title% %status%", {title: txTitle, status: receipt.status === 'success' ? "Confirmed" : "Fail"}),
                  <ToastDescriptionWithTx txHash={receipt.transactionHash} txChainId={chainId}>{transaction.summary}</ToastDescriptionWithTx>,
                )
              }
            }
            merge(completeTransactions.current, { [transaction.hash]: true })
            // merge(fetchedTransactions.current, { [transaction.hash]: transactions[transaction.hash] })
          } catch (error) {
            console.error(error)
            // if (error instanceof TransactionNotFoundError) {
            //   throw new RetryableError(`Transaction not found: ${transaction.hash}`)
            // } else if (error instanceof TransactionReceiptNotFoundError) {
            //   throw new RetryableError(`Transaction receipt not found: ${transaction.hash}`)
            // } else if (error instanceof BlockNotFoundError) {
            //   throw new RetryableError(`Block not found for transaction: ${transaction.hash}`)
            // } else if (error instanceof WaitForTransactionReceiptTimeoutError) {
            //   throw new RetryableError(`Timeout reached when fetching transaction receipt: ${transaction.hash}`)
            // }
          }
          merge(fetchedTransactions.current, { [transaction.hash]: transactions[transaction.hash] })
        }
        retry(getTransaction, {
          n: 10,
          minWait: 5000,
          maxWait: 10000,
        })
      },
    )
  }, [chainId, provider, transactions, dispatch, toastSuccess, toastError, toastInfo, t])

  const nonBscFarmPendingTxns = useMemo(
    () =>
      Object.keys(transactions).filter(
        (hash) =>
          transactions[hash].receipt?.status === 1 &&
          transactions[hash].type === 'non-bsc-farm' &&
          transactions[hash].nonBscFarm?.status === FarmTransactionStatus.PENDING,
      ),
    [transactions],
  )

  useSWRImmutable(
    chainId && Boolean(nonBscFarmPendingTxns?.length) && ['checkNonBscFarmTransaction', FAST_INTERVAL, chainId],
    () => {
      nonBscFarmPendingTxns.forEach((hash) => {
        const steps = transactions[hash]?.nonBscFarm?.steps || []
        if (steps.length) {
          const pendingStep = steps.findIndex(
            (step: NonBscFarmTransactionStep) => step.status === FarmTransactionStatus.PENDING,
          )
          const previousIndex = pendingStep - 1

          if (previousIndex >= 0) {
            const previousHash = steps[previousIndex]
            const checkHash = previousHash.tx || hash

            fetchCelerApi(checkHash)
              .then((response) => {
                const transaction = transactions[hash]
                const { destinationTxHash, messageStatus } = response
                const status =
                  messageStatus === MsgStatus.MS_COMPLETED
                    ? FarmTransactionStatus.SUCCESS
                    : messageStatus === MsgStatus.MS_FAIL
                    ? FarmTransactionStatus.FAIL
                    : FarmTransactionStatus.PENDING
                const isFinalStepComplete = status === FarmTransactionStatus.SUCCESS && steps.length === pendingStep + 1

                const newSteps = transaction?.nonBscFarm?.steps?.map((step, index) => {
                  let newObj = {}
                  if (index === pendingStep) {
                    newObj = { ...step, status, tx: destinationTxHash }
                  }
                  return { ...step, ...newObj }
                })

                dispatch(
                  finalizeTransaction({
                    chainId,
                    hash: transaction.hash,
                    receipt: { ...transaction.receipt },
                    nonBscFarm: {
                      ...transaction.nonBscFarm,
                      steps: newSteps,
                      status: isFinalStepComplete ? FarmTransactionStatus.SUCCESS : transaction?.nonBscFarm?.status,
                    },
                  }),
                )

                const isStakeType = transactions[hash]?.nonBscFarm?.type === NonBscFarmStepType.STAKE
                if (isFinalStepComplete) {
                  const toastTitle = isStakeType ? t('Staked!') : t('Unstaked!')
                  toastSuccess(
                    toastTitle,
                    <ToastDescriptionWithTx txHash={destinationTxHash} txChainId={steps[pendingStep].chainId}>
                      {isStakeType
                        ? t('Your LP Token have been staked in the Farm!')
                        : t('Your LP Token have been unstaked in the Farm!')}
                    </ToastDescriptionWithTx>,
                  )
                } else if (status === FarmTransactionStatus.FAIL) {
                  const toastTitle = isStakeType ? t('Stake Error') : t('Unstake Error')
                  const errorText = isStakeType ? t('Token fail to stake.') : t('Token fail to unstake.')
                  toastError(
                    toastTitle,
                    <ToastDescriptionWithTx txHash={destinationTxHash} txChainId={steps[pendingStep].chainId}>
                      <Box>
                        <Text
                          as="span"
                          bold
                        >{`${transaction?.nonBscFarm?.amount} ${transaction?.nonBscFarm?.lpSymbol}`}</Text>
                        <Text as="span" ml="4px">
                          {errorText}
                        </Text>
                      </Box>
                    </ToastDescriptionWithTx>,
                  )
                }
              })
              .catch((error) => {
                console.error(`Failed to check harvest transaction hash: ${hash}`, error)
              })
          }
        }
      })
    },
    {
      refreshInterval: FAST_INTERVAL,
      errorRetryInterval: FAST_INTERVAL,
      onError: (error) => {
        console.error('[ERROR] updater checking non BSC farm transaction error: ', error)
      },
    },
  )

  return null
}

export default Updater

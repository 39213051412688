import { Link, Text, BscScanIcon, OpenNewIcon } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/chains'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
  txChainId?: number
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({
  txHash,
  txChainId,
  children,
}) => {
  const { chainId } = useActiveChainId()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? <Text as="p" fontSize="inherit">{children}</Text> : children}
      {txHash && (
        <Link external small href={getBlockExploreLink(txHash, 'transaction', txChainId || chainId)} color="textSubtle">
          {t('View transaction details')}
          <OpenNewIcon ml="4px" />
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx

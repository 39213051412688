import { useDebounce } from '@pancakeswap/hooks';
import { useActiveChainId } from 'hooks/useActiveChainId';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { useCurrentBlock } from 'state/block/hooks';
import { multicallReducerAtom, MulticallState } from 'state/multicall/reducer';
import { worker2 } from 'utils/worker';
import { useMulticallContract } from '../../hooks/useContract';
import {
  Call,
  errorFetchingMulticallResults,
  fetchingMulticallResults,
  parseCallKey,
  updateMulticallResults,
} from './actions';
import chunkArray from './chunkArray';
import { CancelledError, retry } from './retry';
import { fetchChunkWithRetry } from './fetchChunk'; // Ensure correct import path

const CALL_CHUNK_SIZE = 500;

export function activeListeningKeys(
  allListeners: MulticallState['callListeners'],
  chainId?: number,
): { [callKey: string]: number } {
  if (!allListeners || !chainId) return {};
  const listeners = allListeners[chainId];
  if (!listeners) return {};

  return Object.keys(listeners).reduce<{ [callKey: string]: number }>((memo, callKey) => {
    const keyListeners = listeners[callKey];

    memo[callKey] = Object.keys(keyListeners)
      .filter((key) => {
        const blocksPerFetch = parseInt(key);
        if (blocksPerFetch <= 0) return false;
        return keyListeners[blocksPerFetch] > 0;
      })
      .reduce((previousMin, current) => {
        return Math.min(previousMin, parseInt(current));
      }, Infinity);
    return memo;
  }, {});
}

export function outdatedListeningKeys(
  callResults: MulticallState['callResults'],
  listeningKeys: { [callKey: string]: number },
  chainId: number | undefined,
  currentBlock: number | undefined,
): string[] {
  if (!chainId || !currentBlock) return [];
  const results = callResults[chainId];
  if (!results) return Object.keys(listeningKeys);

  return Object.keys(listeningKeys).filter((callKey) => {
    const data = callResults[chainId][callKey];
    if (!data) return true;

    const blocksPerFetch = listeningKeys[callKey];
    const minDataBlockNumber = currentBlock - (blocksPerFetch - 1);

    if (data.fetchingBlockNumber && data.fetchingBlockNumber >= minDataBlockNumber) return false;

    return !data.blockNumber || data.blockNumber < minDataBlockNumber;
  });
}

export default function Updater(): null {
  const [state, dispatch] = useAtom(multicallReducerAtom);
  const debouncedListeners = useDebounce(state.callListeners, 100);
  const currentBlock = useCurrentBlock();
  const { chainId } = useActiveChainId();
  const multicallContract = useMulticallContract();
  const cancellations = useRef<{ blockNumber: number; cancellations: (() => void)[] }>();

  const listeningKeys: { [callKey: string]: number } = useMemo(() => {
    return activeListeningKeys(debouncedListeners, chainId);
  }, [debouncedListeners, chainId]);

  const unserializedOutdatedCallKeys = useMemo(() => {
    return outdatedListeningKeys(state.callResults, listeningKeys, chainId, currentBlock);
  }, [chainId, state.callResults, listeningKeys, currentBlock]);

  const serializedOutdatedCallKeys = useMemo(
    () => JSON.stringify(unserializedOutdatedCallKeys.sort()),
    [unserializedOutdatedCallKeys],
  );

  useEffect(() => {
    if (!currentBlock || !chainId || !multicallContract) return;

    const outdatedCallKeys: string[] = JSON.parse(serializedOutdatedCallKeys);
    if (outdatedCallKeys.length === 0) return;
    const calls = outdatedCallKeys.map((key) => parseCallKey(key));

    const chunkedCalls = chunkArray(calls, CALL_CHUNK_SIZE);

    if (cancellations.current?.blockNumber !== currentBlock) {
      cancellations.current?.cancellations?.forEach((c) => c());
    }

    dispatch(
      fetchingMulticallResults({
        calls,
        chainId,
        fetchingBlockNumber: currentBlock,
      }),
    );

    cancellations.current = {
      blockNumber: currentBlock,
      cancellations: chunkedCalls.map((chunk, index) => {
        const { cancel, promise } = retry(
          () =>
            fetchChunkWithRetry(chainId, chunk, currentBlock), // Call fetchChunkWithRetry directly
          {
            n: 5, // Increase retries to 5
            minWait: 2500,
            maxWait: 3500,
          },
        );

        promise
          .then(({ results: returnData, blockNumber: fetchBlockNumber }) => {
            cancellations.current = { cancellations: [], blockNumber: currentBlock };

            const firstCallKeyIndex = chunkedCalls.slice(0, index).reduce<number>((memo, curr) => memo + curr.length, 0);
            const lastCallKeyIndex = firstCallKeyIndex + returnData.length;

            const { erroredCalls, results } = outdatedCallKeys.slice(firstCallKeyIndex, lastCallKeyIndex).reduce<{
              erroredCalls: Call[],
              results: { [callKey: string]: string | null }
            }>(
              (memo, callKey, i) => {
                if (returnData[i].success && returnData[i].returnData !== '0x') {
                  memo.results[callKey] = returnData[i].returnData ?? null;
                } else {
                  // console.error('Failed call:', parseCallKey(callKey), 'with data:', returnData[i].returnData, 'at block', fetchBlockNumber);
                  memo.erroredCalls.push(parseCallKey(callKey));
                }
                return memo;
              }, { erroredCalls: [], results: {} }
            );

            if (Object.keys(results).length > 0) {
              dispatch(
                updateMulticallResults({
                  chainId,
                  results,
                  blockNumber: fetchBlockNumber,
                }),
              );
            }

            if (erroredCalls.length > 0) {
              dispatch(
                errorFetchingMulticallResults({
                  calls: erroredCalls,
                  chainId,
                  fetchingBlockNumber: fetchBlockNumber,
                }),
              );
            }
          })
          .catch((error: any) => {
            const REVERT_STR = 'revert exception';

            if (error instanceof CancelledError || error?.message?.indexOf(REVERT_STR) >= 0) {
              console.debug('Cancelled fetch for blockNumber', currentBlock);
              return;
            }
            console.error('Failed to fetch multicall chunk', chunk, chainId, error, currentBlock);
            dispatch(
              errorFetchingMulticallResults({
                calls: chunk,
                chainId,
                fetchingBlockNumber: currentBlock,
              }),
            );
          });
        return cancel;
      }),
    };
  }, [chainId, multicallContract, dispatch, serializedOutdatedCallKeys, currentBlock]);

  return null;
}

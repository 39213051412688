import { ChainId } from '@pancakeswap/chains'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { pulseTokens } from '@pancakeswap/tokens'
import contracts from 'config/constants/contracts'
import { publicClient } from 'utils/wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { multiChainName } from 'state/info/constant'
import { fetchDerivedUSDPricePerAddress } from 'state/info/queries/pools/poolData'
import { formatUnits } from 'viem'
import { FAST_INTERVAL } from 'config/constants'
import { useQuery } from '@tanstack/react-query'

// for migration to bignumber.js to avoid breaking changes
// export const useCakePrice = ({ enabled = true } = {}) => {

  
//   const { data } = useQuery<BigNumber, Error>({
//     queryKey: ['cakePrice'],
//     queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
//     staleTime: FAST_INTERVAL,
//     refetchInterval: FAST_INTERVAL,
//     enabled,
//   })
//   return data ?? BIG_ZERO
// }

export const useBBCPrice = ({ enabled = true } = {}) => {
  const { chainId } = useActiveChainId()

  const { data } = useQuery<BigNumber, Error>({
    queryKey: ['bbcPrice', chainId],
    queryFn: async () => {
      const result = await fetchDerivedUSDPricePerAddress(
        pulseTokens.bbc.address.toLowerCase(),
        multiChainName[chainId!]
      )
      if (result.error) {
        throw new Error(`Error fetching price for address ${pulseTokens.bbc.symbol}`)
      }
      return new BigNumber(result.price ?? 0)
    },
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled: enabled && !!chainId,
    retry: false,
    onError: (error) => {
      console.error('BBC price fetch error:', error)
    },
  })

  return data ?? BIG_ZERO
}

export const useCakePrice = useBBCPrice

export const getCakePriceFromOracle = async () => {
  return '0'
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: contracts.chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}

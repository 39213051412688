import { parseEther } from 'viem'
import {
  ButtonMenu,
  Button,
  CloseIcon,
  Heading,
  Text,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalWrapper,
  ModalTitle,
  ModalCloseButton,
  Flex
} from '@pancakeswap/uikit'
import { useAccount, useBalance } from 'wagmi'
import { useState, useCallback } from 'react'
import { useTranslation } from '@pancakeswap/localization'
import { styled } from 'styled-components'
import useAuth from 'hooks/useAuth' // Add this import

import EBoxSm from 'components/EBoxSm'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import isEmpty from 'lodash/isEmpty'

import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
  isTopRight?: boolean // Add this new prop
}

export const LOW_NATIVE_BALANCE = parseEther('0.002', 'wei')

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #958BB0;
  margin: 0;
  opacity: 0.5;
`

const Tabs = styled.div`
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 16px 24px;
`
const ButtonMenuItem = styled(Button)`
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
`

interface TabsComponentProps {
  view: WalletView
  handleClick: (newIndex: number) => void
}

const TabsComponent: React.FC<React.PropsWithChildren<TabsComponentProps>> = ({ view, handleClick }) => {
  const { t } = useTranslation()

  return (
    <Tabs>
      <ButtonMenu scale="sm" variant="subtle" onItemClick={handleClick} activeIndex={view} fullWidth>
        <ButtonMenuItem>Wallet</ButtonMenuItem>
        <ButtonMenuItem>Transactions</ButtonMenuItem>
      </ButtonMenu>
    </Tabs>
  )
}

const WalletModal: React.FC<React.PropsWithChildren<WalletModalProps>> = ({
  initialView = WalletView.WALLET_INFO,
  onDismiss,
  isTopRight = false, // Add this new prop with a default value
}) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { data, isFetched } = useBalance({ address: account })
  const hasLowNativeBalance = isFetched && data && data.value <= LOW_NATIVE_BALANCE
  const { logout } = useAuth() // Add this line

  const transactions = useAllSortedRecentTransactions()

  const handleClick = useCallback((newIndex: number) => {
    setView(newIndex)
  }, [])

  return (
    <ModalWrapper minWidth="320px" maxWidth="400px" isTopRight={isTopRight}>
      <ModalBody p="24px" width="100%">
        <Flex justifyContent="flex-end" mb="16px">
          <ModalCloseButton onDismiss={onDismiss} />
        </Flex>
        <WalletInfo hasLowNativeBalance={hasLowNativeBalance} switchView={handleClick} onDismiss={onDismiss} />
        <Divider />
        {
          !isEmpty(transactions) &&
          <>
            <Flex justifyContent="space-between" alignItems="center" mb="16px">
              <Heading>Recent transactions</Heading>
              {/* <ButtonMenuItem as="a" href="#">View all &gt;</ButtonMenuItem> */}
            </Flex>
            <WalletTransactions onDismiss={onDismiss} />
            {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
            
            {/* Add the disconnect button */}
            <Divider />
          </>
        }
        <ButtonMenuItem 
          onClick={() => {
            logout()
            onDismiss()
          }}
          style={{ width: '100%', marginTop: '16px' }}
        >
          <Text bold fontFamily="Riffic Free">{t('Disconnect')}</Text>
        </ButtonMenuItem>
      </ModalBody>
    </ModalWrapper>
  )
}

export default WalletModal

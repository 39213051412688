import memoize from 'lodash/memoize'
// @ts-ignore
import { Token } from '@pancakeswap/sdk'
import { ChainId } from '@pancakeswap/chains'
import { safeGetAddress } from 'utils'
import { isAddress } from 'viem'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.POLYGON_ZKEVM]: 'polygonzkevm',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.LINEA]: 'linea',
  [ChainId.BLOCKSPOT_TESTNET]: 'blockspot',

  [ChainId.PULSE]: 'pulse',
}

// const getTokenLogoURL = memoize(
//   (token?: Token) => {
//     if (token?.symbol) {
//       return `https://v2.9inch.io/images/symbols/${token.symbol.toLowerCase()}.png`
//     } else {
//       return `https://v2.9inch.io/images/tokens/${token.address}.png`
//     }
//     return null
//   },
//   (t) => `${t?.chainId}#${t?.address}`,
// )

const getTokenLogoURL = memoize(
  (token?: Token) => token?.symbol
    ? `https://v2.9inch.io/images/symbols/${token.symbol.toLowerCase()}.png`
    : `https://v2.9inch.io/images/tokens/${token?.address}.png`,
  (t) => `${t?.chainId}#${t?.address}`,
)

export const getTokenLogoURLWithSymbol = (tokenSymbol?: string) => {
  if (tokenSymbol) return `https://v2.9inch.io/images/symbols/${tokenSymbol}.png`
  return null
}

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId] && isAddress(address)) {
      return `https://v2.9inch.io/images/tokens/${address}.png`
      // return `https://v2.9inch.io/images/chains/${chainId}.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL

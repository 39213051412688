import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { bscTokens, arbitrumTokens } from '@pancakeswap/tokens'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import { getFarmsPrices } from '@pancakeswap/farms/farmPrices'
import {
  fetchPoolsTimeLimits,
  fetchPoolsTotalStaking,
  fetchPoolsProfileRequirement,
  fetchPoolsStakingLimits,
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
  fetchPublicIfoData,
  fetchUserIfoCredit,
  fetchPublicVaultData,
  fetchPublicFlexibleSideVaultData,
  fetchVaultUser,
  fetchVaultFees,
  fetchFlexibleSideVaultUser,
  getCakeVaultAddress,
  getCakeFlexibleSideVaultAddress,
  getPoolsConfig,
  isLegacyPool,
  getPoolAprByTokenPerSecond,
  getPoolAprByTokenPerBlock,
  fetchPoolsRewardPerBlock,
  VaultKey,
} from '@pancakeswap/pools'
import { ChainId } from '@pancakeswap/chains'

import {
  PoolsState,
  SerializedPool,
  SerializedVaultFees,
  SerializedCakeVault,
  SerializedLockedVaultUser,
  PublicIfoData,
  SerializedVaultUser,
  SerializedLockedCakeVault,
} from 'state/types'
import { Address, erc20ABI } from 'wagmi'
import { safeGetAddress } from 'utils'
import { publicClient } from 'utils/wagmi'
import { getViemClients } from 'utils/viem'
import { fetchTokenUSDValue } from 'utils/llamaPrice'
// import { getPoolsPriceHelperLpFiles } from 'config/constants/priceHelperLps'
// import { farmV3ApiFetch } from 'state/farmsV3/hooks'
import { getCakePriceFromOracle } from 'hooks/useCakePrice'
import { fetchTokenAplPrice } from 'utils/fetchTokenAplPrice'
import { fetchTokenPrices } from 'views/Info/hooks/useBnbPrices'

// import fetchFarms from '../farms/fetchFarms'
// import { nativeStableLpMap } from '../farms/getFarmsPrices'
// import { getTokenPricesFromFarm } from './helpers'
import { resetUserState } from '../global/actions'
// import { dispatch } from 'd3'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

export const initialIfoState = Object.freeze({
  credit: null,
  ceiling: null,
})

const initialState: PoolsState = {
  data: [],
  ifo: initialIfoState,
  userDataLoaded: false,
  prices: {},
}

export const fetchCakePoolPublicDataAsync = () => async (dispatch) => {
  const cakePrice = parseFloat(await getCakePriceFromOracle())

  const stakingTokenPrice = cakePrice
  const earningTokenPrice = cakePrice

  dispatch(
    setPoolPublicData({
      sousId: 0,
      data: {
        stakingTokenPrice,
        earningTokenPrice,
      },
    }),
  )
}

export const fetchCakePoolUserDataAsync =
  ({ account, chainId }: { account: string; chainId: ChainId }) =>
    async (dispatch) => {
      const client = publicClient({ chainId: ChainId.BSC })
      const [allowance, stakingTokenBalance] = await client.multicall({
        contracts: [
          {
            abi: erc20ABI,
            address: bscTokens.cake.address,
            functionName: 'allowance',
            args: [account as Address, getCakeVaultAddress(chainId)],
          },
          {
            abi: erc20ABI,
            address: bscTokens.cake.address,
            functionName: 'balanceOf',
            args: [account as Address],
          },
        ],
        allowFailure: false,
      })

      dispatch(
        setPoolUserData({
          sousId: 0,
          data: {
            allowance: new BigNumber(allowance.toString()).toJSON(),
            stakingTokenBalance: new BigNumber(stakingTokenBalance.toString()).toJSON(),
          },
        }),
      )
    }

export const fetchPricesAsync = (chainId: number) => async (dispatch, getState) => {
  const poolsConfig = getPoolsConfig(chainId) || []
  const addresses = [
    ...new Set([
      // ...Object.keys(prices),
      ...poolsConfig.map(pool => pool.stakingToken.address),
      ...poolsConfig.map(pool => pool.earningToken.address)
    ])
  ]
  const tokenPrices = await fetchTokenPrices(chainId, addresses)
  dispatch(setPrices(tokenPrices))
}

export const fetchPoolsPublicDataAsync = (chainId: number) => async (dispatch, getState) => {
  try {
    const [block, blockLimits, rewardsPerBlock] = await Promise.all([
      getViemClients({ chainId })?.getBlock({ blockTag: 'latest' }),
      fetchPoolsTimeLimits(chainId, getViemClients),
      fetchPoolsRewardPerBlock(chainId, getViemClients),
    ])
    const timeLimitsSousIdMap = keyBy(blockLimits, 'sousId')
    const rewardsPerBlockSousIdMap = keyBy(rewardsPerBlock, 'sousId')
    // const priceHelperLpsConfig = getPoolsPriceHelperLpFiles(chainId)
    const poolsConfig = getPoolsConfig(chainId) || []
    // const activePriceHelperLpsConfig = priceHelperLpsConfig.filter((priceHelperLpConfig) => {
    //   return (
    //     poolsConfig
    //       .filter((pool) => pool.earningToken.address.toLowerCase() === priceHelperLpConfig.token.address.toLowerCase())
    //       .filter((pool) => {
    //         const poolTimeLimit = timeLimitsSousIdMap[pool.sousId]
    //         if (poolTimeLimit) {
    //           return poolTimeLimit.endTimestamp > Number(block.timestamp)
    //         }
    //         return false
    //       }).length > 0
    //   )
    // })

    // const fetchFarmV3Promise = farmV3ApiFetch(chainId)
    //   .then((result) => result?.farmsWithPrice || [])
    //   .catch(() => {
    //     return []
    //   })

    const [totalStakings, profileRequirements] = await Promise.all([
      fetchPoolsTotalStaking(chainId, getViemClients),
      fetchPoolsProfileRequirement(chainId, getViemClients),
      // activePriceHelperLpsConfig.length > 0 ? fetchFarms(priceHelperLpsConfig, chainId) : Promise.resolve([]),
      // fetchFarmV3Promise,
    ])

    const totalStakingsSousIdMap = keyBy(totalStakings, 'sousId')

    // const farmsV2Data = getState().farms.data
    // const bnbBusdFarms =
    //   activePriceHelperLpsConfig.length > 0
    //     ? [...orderBy(farmsV3Data, 'lmPoolLiquidity', 'desc'), ...farmsV2Data].filter(
    //       (farm) => farm.token.symbol === 'DAI' && farm.quoteToken.symbol === 'WPLS',
    //     )
    //     : []
    // const farmsWithPricesOfDifferentTokenPools =
    //   bnbBusdFarms.length > 0
    //     ? getFarmsPrices([...bnbBusdFarms, ...poolsWithDifferentFarmToken], nativeStableLpMap[chainId], 18)
    //     : []

    // const prices = getTokenPricesFromFarm([...farmsV2Data, ...farmsV3Data, ...farmsWithPricesOfDifferentTokenPools])
    // const addresses = [
    //   ...new Set([
    //     // ...Object.keys(prices),
    //     ...poolsConfig.map(pool => pool.stakingToken.address),
    //     ...poolsConfig.map(pool => pool.earningToken.address)
    //   ])
    // ]
    // const tokenPrices = await fetchTokenPrices(chainId, addresses)
    const tokenPrices = getState().pools.prices

    const liveData: any[] = []

    for (const pool of poolsConfig) {
      const timeLimit = timeLimitsSousIdMap[pool.sousId]
      const totalStaking = totalStakingsSousIdMap[pool.sousId]
      const rewardPerBlock = rewardsPerBlockSousIdMap[pool.sousId]
      const isPoolEndBlockExceeded =
        block.timestamp > 0 && timeLimit ? block.timestamp > Number(timeLimit.endTimestamp) : false
      const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

      const stakingTokenAddress = safeGetAddress(pool.stakingToken.address)
      let stakingTokenPrice = stakingTokenAddress ? tokenPrices[stakingTokenAddress] : 0
      if (stakingTokenAddress && !tokenPrices[stakingTokenAddress] && !isPoolFinished) {
        // TODO: Remove this when fetchTokenUSDValue can get APL USD Price
        if (
          pool.stakingToken.chainId === ChainId.ARBITRUM_ONE &&
          pool.stakingToken.address === arbitrumTokens.alp.address
        ) {
          // eslint-disable-next-line no-await-in-loop
          stakingTokenPrice = await fetchTokenAplPrice()
        } else {
          // eslint-disable-next-line no-await-in-loop
          // const result = await fetchTokenUSDValue(chainId, [stakingTokenAddress])
          // eslint-disable-next-line no-await-in-loop
          const result = await fetchTokenPrices(chainId, [stakingTokenAddress])
          stakingTokenPrice = result?.[stakingTokenAddress]
        }
      }

      const earningTokenAddress = safeGetAddress(pool.earningToken.address)
      let earningTokenPrice = earningTokenAddress ? tokenPrices[earningTokenAddress] : 0
      if (earningTokenAddress && !tokenPrices[earningTokenAddress] && !isPoolFinished) {
        // eslint-disable-next-line no-await-in-loop
        // const result = await fetchTokenUSDValue(chainId, [earningTokenAddress])
        // earningTokenPrice = result.get(earningTokenAddress) || 0.001
        // eslint-disable-next-line no-await-in-loop
        const result = await fetchTokenPrices(chainId, [earningTokenAddress])
        earningTokenPrice = result?.[stakingTokenAddress]
      }
      const totalStaked = getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals)
      const apr = !isPoolFinished && !(pool as any).vaultKey
        ? isLegacyPool(pool)
          ? getPoolAprByTokenPerBlock(stakingTokenPrice, earningTokenPrice, totalStaked, parseFloat(rewardPerBlock.tokenPerBlock))
          : getPoolAprByTokenPerSecond(
            stakingTokenPrice,
            earningTokenPrice,
            totalStaked,
            parseFloat(pool.tokenPerSecond),
          )
        : 0

      const profileRequirement = profileRequirements[pool.sousId] ? profileRequirements[pool.sousId] : undefined

      liveData.push({
        vaultKey: (pool as any).vaultKey,
        ...timeLimit,
        ...totalStaking,
        ...rewardPerBlock,
        profileRequirement,
        stakingTokenPrice,
        earningTokenPrice,
        apr,
        isFinished: isPoolFinished,
      })
    }

    dispatch(setPoolsPublicData(liveData || []))
  } catch (error) {
    console.error('[Pools Action] error when getting public data', error)
  }
}

export const fetchPoolsStakingLimitsAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.sousId)

  try {
    const stakingLimits = await fetchPoolsStakingLimits({ poolsWithStakingLimit, chainId, provider: getViemClients })

    const poolsConfig = getPoolsConfig(chainId)
    const stakingLimitData = poolsConfig?.filter((pool: any) => !pool.vaultKey).map((pool) => {
      if (poolsWithStakingLimit.includes(pool.sousId)) {
        return { sousId: pool.sousId }
      }
      const { stakingLimit, numberSecondsForUserLimit } = stakingLimits[pool.sousId] || {
        stakingLimit: BIG_ZERO,
        numberSecondsForUserLimit: 0,
      }
      return {
        sousId: pool.sousId,
        stakingLimit: stakingLimit.toJSON(),
        numberSecondsForUserLimit,
      }
    })
    if (stakingLimitData) {
      dispatch(setPoolsPublicData(stakingLimitData))
    }
  } catch (error) {
    console.error('[Pools Action] error when getting staking limits', error)
  }
}

export const fetchPoolsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[],
  {
    account: string
    chainId: ChainId
  }
>('pool/fetchPoolsUserData', async ({ account, chainId }, { rejectWithValue }) => {
  try {
    const [allowances, stakingTokenBalances, stakedBalances, pendingRewards] = await Promise.all([
      fetchPoolsAllowance({ account, chainId, provider: getViemClients }),
      fetchUserBalances({ account, chainId, provider: getViemClients }),
      fetchUserStakeBalances({ account, chainId, provider: getViemClients }),
      fetchUserPendingRewards({ account, chainId, provider: getViemClients }),
    ])

    const poolsConfig = getPoolsConfig(chainId)
    const userData = poolsConfig?.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const updateUserAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserAllowance', async ({ sousId, account, chainId }) => {
  const allowances = await fetchPoolsAllowance({ account, chainId, provider: getViemClients })
  return { sousId, field: 'allowance', value: allowances[sousId] }
})

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserBalance', async ({ sousId, account, chainId }) => {
  const tokenBalances = await fetchUserBalances({ account, chainId, provider: getViemClients })
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserStakedBalance', async ({ sousId, account, chainId }) => {
  const stakedBalances = await fetchUserStakeBalances({ account, chainId, provider: getViemClients })
  return { sousId, field: 'stakedBalance', value: stakedBalances[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: ChainId }
>('pool/updateUserPendingReward', async ({ sousId, account, chainId }) => {
  const pendingRewards = await fetchUserPendingRewards({ chainId, account, provider: getViemClients })
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})

export const fetchVaultsPublicData = createAsyncThunk<
  { [vaultKey in VaultKey]?: SerializedLockedCakeVault | SerializedCakeVault },
  ChainId
>(
  'vaults/fetchPublicData',
  async (chainId) => {
    const publicVaultInfo = await fetchPublicVaultData({ chainId, provider: getViemClients })
    return publicVaultInfo
  },
)

export const fetchCakeFlexibleSideVaultPublicData = createAsyncThunk<SerializedCakeVault, ChainId>(
  'cakeFlexibleSideVault/fetchPublicData',
  async (chainId) => {
    const publicVaultInfo = await fetchPublicFlexibleSideVaultData({ chainId, provider: getViemClients })
    return publicVaultInfo
  },
)

export const fetchCakeVaultFees = createAsyncThunk<SerializedVaultFees, ChainId>(
  'vaults/fetchFees',
  async (chainId) => {
    const vaultFees = await fetchVaultFees({
      chainId,
      provider: getViemClients,
      // cakeVaultAddress: getCakeVaultAddress(chainId),
    })
    return vaultFees
  },
)

export const fetchCakeFlexibleSideVaultFees = createAsyncThunk<SerializedVaultFees, ChainId>(
  'cakeFlexibleSideVault/fetchFees',
  async (chainId) => {
    const vaultFees = await fetchVaultFees({
      chainId,
      provider: getViemClients,
      cakeVaultAddress: getCakeFlexibleSideVaultAddress(chainId),
    })
    return vaultFees
  },
)

export const fetchVaultsUserData = createAsyncThunk<
  { [vaultKey in VaultKey]?: SerializedLockedVaultUser | SerializedVaultUser },
  { account: Address; chainId: ChainId }
>('vaults/fetchUser', async ({ account, chainId }) => {
  const userData = await fetchVaultUser({ account, chainId, provider: getViemClients })
  return userData
})

export const fetchIfoPublicDataAsync = createAsyncThunk<PublicIfoData, ChainId>(
  'ifoVault/fetchIfoPublicDataAsync',
  async (chainId) => {
    const publicIfoData = await fetchPublicIfoData(chainId, getViemClients)
    return publicIfoData
  },
)

// export const fetchUserIfoCreditDataAsync = ({
//   account, chainId
// }: { account: Address; chainId: ChainId }) =>
//   async (dispatch) => {
//     try {
//       const credit = await fetchUserIfoCredit({ account, chainId, provider: getViemClients })
//       dispatch(setIfoUserCreditData(credit))
//     } catch (error) {
//       console.error('[Ifo Credit Action] Error fetching user Ifo credit data', error)
//     }
//   }
// export const fetchCakeFlexibleSideVaultUserData = createAsyncThunk<
//   SerializedVaultUser,
//   { account: Address; chainId: ChainId }
// >('cakeFlexibleSideVault/fetchUser', async ({ account, chainId }) => {
//   const userData = await fetchFlexibleSideVaultUser({ chainId, account, provider: getViemClients })
//   return userData
// })

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setInitialPoolConfig: (state, action) => {
      const { chainId } = action.payload
      const poolsConfig = getPoolsConfig(chainId) || []
      state.data = [...poolsConfig]
      state.userDataLoaded = false
    },
    setPrices: (state, action) => {
      const prices = action.payload
      state.prices = { ...state.prices, ...prices }
    },
    setPoolPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((pool) => {
        if (pool.sousId === sousId) {
          return { ...pool, userDataLoaded: true, userData: action.payload.data }
        }
        return pool
      })
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = keyBy(livePoolsData, 'sousId')
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsSousIdMap[pool.sousId]
        return { ...pool, ...livePoolData }
      })
      livePoolsData.filter(pool => !!pool.vaultKey).forEach(pool => {
        state[pool.vaultKey] = {
          ...initialPoolVaultState,
          ...state[pool.vaultKey],
          stakingTokenPrice: pool.stakingTokenPrice,
          earningTokenPrice: pool.earningTokenPrice,
        }
      })
    },
    // IFO
    // setIfoUserCreditData: (state, action) => {
    //   const credit = action.payload
    //   // state.ifo = { ...state.ifo, credit }
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
      // state.cakeVault = { ...state.cakeVault, userData: initialPoolVaultState.userData }
      // state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData: initialPoolVaultState.userData }
    })
    builder.addCase(
      fetchPoolsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = keyBy(userData, 'sousId')
        state.data = state.data.map((pool) => ({
          ...pool,
          userDataLoaded: true,
          userData: userDataSousIdMap[pool.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    // builder.addCase(fetchPoolsUserDataAsync.rejected, (state, action) => {
    //   console.error('[Pools Action] Error fetching pool user data', action.payload)
    // })
    // Vault public data that updates frequently
    builder.addCase(fetchVaultsPublicData.fulfilled, (state, action: PayloadAction<any>) => {
      for (const vaultKey in action.payload) {
        if (action.payload[vaultKey])
          state[vaultKey] = { ...initialPoolVaultState, ...state[vaultKey], ...action.payload[vaultKey] }
      }
    })
    // builder.addCase(
    //   fetchCakeFlexibleSideVaultPublicData.fulfilled,
    //   (state, action: PayloadAction<SerializedCakeVault>) => {
    //     state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, ...action.payload }
    //   },
    // )
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      for (const vaultKey in action.payload) {
        if (action.payload[vaultKey])
          state[vaultKey] = { ...initialPoolVaultState, ...state[vaultKey], fees: action.payload[vaultKey] }
      }
    })
    // builder.addCase(fetchCakeFlexibleSideVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      // const fees = action.payload
      // state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, fees }
    // })
    // Vault user data
    builder.addCase(fetchVaultsUserData.fulfilled, (state, action: PayloadAction<any>) => {
      for (const vaultKey in action.payload) {
        if (action.payload[vaultKey])
          state[vaultKey] = { ...initialPoolVaultState, ...state[vaultKey], userData: action.payload[vaultKey] }
      }
    })
    // IFO
    // builder.addCase(fetchIfoPublicDataAsync.fulfilled, (state, action: PayloadAction<PublicIfoData>) => {
    //   const { ceiling } = action.payload
    //   state.ifo = { ...state.ifo, ceiling }
    // })
    // builder.addCase(
    //   fetchCakeFlexibleSideVaultUserData.fulfilled,
    //   (state, action: PayloadAction<SerializedVaultUser>) => {
    //     const userData = action.payload
    //     state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData }
    //   },
    // )
    builder.addMatcher(
      isAnyOf(
        updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserStakedBalance.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setPrices, setPoolsPublicData, setPoolPublicData, setPoolUserData, setInitialPoolConfig } = PoolsSlice.actions

export default PoolsSlice.reducer

export const breakpoints = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
  xxl: 1200,
} as const;

export const mediaQueries = {
  xs: `@media screen and (max-width: ${breakpoints.sm}px)`,
  
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,

  _sm: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
  _md: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  _lg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  _xl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  _xxl: `@media screen and (max-width: ${breakpoints.xxl - 1}px)`,
};

export type Breakpoint = keyof typeof breakpoints;

export const breakpointNames = Object.keys(breakpoints) as Breakpoint[];

import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems, MenuItemsType, SwapFillIcon, SwapIcon, DropdownMenuItemType, MoreVerticalIcon } from '@pancakeswap/uikit'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}


const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: true,
        items: [
          {
            label: t('Swap'),
            subtitle: t('Swap between any tokens on the 9inch DEX V2 + V3'),
            href: '/swap',
            showItemsOnMobile: true,
          },
          {
            label: t('DCA'),
            subtitle: t('Automate buys and sells according to your own preferences'),
            href: '/dca',
            showItemsOnMobile: true,
            // disabled: true,
          },
          {
            label: t('Limit Order'),
            subtitle: t('Set a specific price for your buy-and-sell orders'),
            href: '/limitOrders',
            showItemsOnMobile: true,
            disabled: true,
          },
        ]
      },
      // {
      //   label: t('HyperPools'),
      //   href: '/hyperpools',
      //   showItemsOnMobile: true,
      // },
      // {
      //   label: t('xNineinch'),
      //   href: '/xnineinch',
      //   showItemsOnMobile: true,
      //   items: [
      //     {
      //       label: t('Dashboard'),
      //       href: '/xnineinch',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Dividends'),
      //       href: '/xnineinch/dividends',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Launchpad'),
      //       href: '/xnineinch/launchpad',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Yield Booster'),
      //       href: '/xnineinch/booster',
      //       showItemsOnMobile: true,
      //     },
      //   ],
      // },
      // {
      //   label: t('Launchpad'),
      //   href: '/launchpad',
      //   showItemsOnMobile: true,
      // },
      /* {
        label: t('Gauge'),
        href: '/gauge',
        showItemsOnMobile: true,
      }, */
      {
        label: t('Earn'),
        href: '/farms',
        showItemsOnMobile: true,
        items: [
          {
            label: t('💧 Liquidity'),
            subtitle: t('See your liquidity, or add/remove a new position'),
            href: '/liquidity',
            showItemsOnMobile: true,
          },
          {
            label: t('Farms'),
            subtitle: t('Use your LP tokens to earn BBC'),
            href: '/farms',
            showItemsOnMobile: true,
          },
          // {
          //   label: t('pFarms'),
          //   subtitle: t('Use your LP tokens to earn BBC on copied-over PRC20 coins from Ethereum'),
          //   href: '/prc20farms',
          //   showItemsOnMobile: true,
          //   disabled: false,
          // },
          {
            label: t('Staking'),
            subtitle: t('Add coins to a pool (Single coin only) and earn BBC'),
            href: '/pools',
            showItemsOnMobile: true,
          },
          {
            label: t('🔥 BBC Pools'),
            subtitle: t('Stake BBC and earn new coins'),
            href: '/coinpools',
            showItemsOnMobile: true,
          },
          // {
          //   label: t('DCA'),
          //   href: '/dca',
          //   showItemsOnMobile: true,
          // },
          // {
          //   label: t('Limit Orders'),
          //   href: '/limitOrders',
          //   showItemsOnMobile: true,
          // },
        ]
      },

      // {
      //   label: t('Tools'),
      //   href: '/tools',
      //   showItemsOnMobile: true,
      //   items: [
      //     {
      //       label: t('Deploy Token'),
      //       href: '/mintToken',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Tool2'),
      //       href: '/tool2',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Tool3'),
      //       href: '/tool3',
      //       showItemsOnMobile: true,
      //     },
      //   ]
      // },
      {
        label: t('Launch'),
        href: '/tools',
        showItemsOnMobile: true,
        items: [
          {
            label: t('🔥 coin.quest'),
            subtitle: t('A gamified degen memecoin marketplace! Trade 100% fair-launch coins without any rugpull risk.'),
            href: 'https://coin.quest/',
            highlights: 'New!',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Create a token'),
            subtitle: t('Launch a token with a logo, liquidity, and other options'),
            href: '/mintToken',
            showItemsOnMobile: true,
          },
          {
            label: t('Coinhub'),
            subtitle: t('Talk about your project in Coinhub Telegram'),
            href: 'https://t.me/coinhub_9inch',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      // {
      //   label: t('Liquidity'),
      //   href: '/add/PLS/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f?chain=pulse',
      //   showItemsOnMobile: true,
      //   items: [
      //     {
      //       label: t('💧 Add Liquidity'),
      //       href: '/add/PLS/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f?chain=pulse',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Positions'),
      //       href: '/positions',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Stableswap '),
      //       highlights: "NEW!",
      //       href: '/add/0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
      //       showItemsOnMobile: true,
      //     },
      //   //   {
      //   //     label: t('Liquidity V2'),
      //   //     href: '/liquidityv2',
      //   //     showItemsOnMobile: true,
      //   //   },
      //    ]
      // },
      {
        label: "Tools",
        href: '',
        items: [
          {
            label: t('Airdrop Tool'),
            subtitle: t(`Airdrop tokens to multiple wallets without any fee's!`),
            // href: 'https://bridge.pulsechain.com',
            // type: DropdownMenuItemType.EXTERNAL_LINK,
            href: '/airdrop',
          },
          {
            label: t('Burn'),
            subtitle: t('Earn a reward by running a public function which buys and burns 9inch & BBC'),
            href: '/burn',
            type: '/burn',
          },
          {
            label: t('Bridge'),
            subtitle: t('Bridge tokens between Pulsechain and Ethereum'),
            href: 'https://bridge.pulsechain.com',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Block explorer'),
            subtitle: t('Scan Pulsechain transactions, view token info, and track wallets'),
            href: 'https://scan.9inch.io/#/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('PulseX'),
            subtitle: t('Use the original Pulsechain DEX made by Richard Heart'),
            href: 'https://app.pulsex.com',
            type: DropdownMenuItemType.EXTERNAL_LINK,
            showItemsOnMobile: true,
          },
          {
            label: t('HEX Staking'),
            subtitle: t('Stake your HEX tokens on Pulsechain and Ethereum'),
            href: 'https://go.hex.com',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
          {
            label: t('👀 Analytics'),
            subtitle: t('See live info on liquidity, tokens, volume and other useful stuff on 9INCH DEX'),
            href: '/info/v3',
            showItemsOnMobile: true,
          }

        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t(''),
        icon: MoreVerticalIcon,
        // disabled: true,
        // showItemsOnMobile: false,
        items: [
          {
            label: t('9INCH V2 (ETH + PLS)'),
            subtitle: t('Legacy 9inch V2 site'),
            href: 'https://v2.9inch.io',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('9INCH V3 (Pulsechain)'),
            subtitle: t('Legacy 9inch V3 site'),
            href: 'https://v3.9inch.io',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Report Issue'),
            subtitle: t('Having any issues? Fill in the form to get it resolved'),
            href: 'https://near.tl/sm/4sMdVkIpd',
            // disabled: true,
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Submit token logo'),
            subtitle: t('Submit a new token logo or update an existing one'),
            href: 'https://forms.gle/BGuDWPTcGzALT4ey5',
            disabled: false,
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          // {
          //   label: t('Web'),
          //   href: 'https://www.9inch.io',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('9INCH'),
          //   href: 'https://x.com/9inch_io',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('CoinHub'),
          //   href: 'https://x.com/coinhub_9inch',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('Chat'),
          //   href: 'https://t.me/dex9inch',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('CoinQuest'),
          //   href: 'https://x.com/coindotquest',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('🔥 coin.quest'),
        href: 'https://coin.quest/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      }

    ].map((item) => addMenuItemSupported(item, chainId))

export default config

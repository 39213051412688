import React, { useCallback, useEffect, useRef, useState, forwardRef, CSSProperties } from "react";
import { CSSTransition } from "react-transition-group";
import { styled, keyframes } from "styled-components";
import { ToastProps, types } from "./types";
import { AddCircleIcon, CheckmarkCircleIcon, CloseIcon, ErrorIcon, WarningIcon } from "../Svg";

const progressAnimation = keyframes`
  from { width: 100%; }
  to { width: 0%; }
`;

const StyledToastBase = styled.div<{ type: string }>`
  position: relative;
  background: #251621;
  border-radius: 12px;
  border: 1px solid #FFF3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 100%;
  overflow: hidden;
  transition: opacity 250ms, transform 250ms;

  ${({ type }) => {
    if (type === "success") return "border-color: #30a75f;";
    if (type === "danger") return "border-color: red;";
    if (type === "warning") return "border-color: orange;";
    return ""; 
  }}
  &.enter, &.appear {
    opacity: 0;
    transform: translateX(100%);
  }

  &.enter-active, &.appear-active {
    opacity: 1;
    transform: translateX(0);
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: translateX(100%);
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: min(100vw, 360px);
  }
`;

const StyledToast = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & {type: string}>((props, ref) => (
  <StyledToastBase ref={ref} {...props} />
));

const ProgressBar = styled.div<{ duration: number, type: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: white;
  ${({ type }) => {
    if (type === "success") return "background: #30a75f;";
    if (type === "danger") return "background: red;";
    if (type === "warning") return "background: orange;";
    return "";
  }}
  animation: ${progressAnimation} ${({ duration }) => duration}ms linear;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  gap: 8px;

`;

const TitleText = styled.span`
  color: white;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
  font-family: Riffic Free;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 20px;
  margin-left: auto;
`;

const Description = styled.div`
  color: #b7b7b7;
  margin: 0;
  font-size: 16px;
  padding-left: 40px;
`;

const Link = styled.a`
  color: #b7b7b7;
  text-decoration: underline;
  font-size: 12px;
`;

interface ExtendedToastProps extends Omit<ToastProps, 'style'> {
  style?: CSSProperties;
}

export const Toast: React.FC<React.PropsWithChildren<ExtendedToastProps>> = ({ 
  toast, 
  onRemove, 
  style, 
  ttl = 3800, 
  ...props 
}) => {
  const timer = useRef<number>();
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const { id, title, description, type, icon } = toast;

  const handleRemove = useCallback(() => {
    setIsVisible(false);
    window.setTimeout(() => onRemove(id), 250);
  }, [id, onRemove]);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
  };

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      handleRemove();
    }, ttl);
  };

  useEffect(() => {
    timer.current = window.setTimeout(() => {
      handleRemove();
    }, ttl);

    return () => {
      clearTimeout(timer.current);
    };
  }, [ttl, handleRemove]);

  return (
    <CSSTransition
      nodeRef={ref}
      timeout={250}
      in={isVisible}
      unmountOnExit
      {...props}
    >
      <StyledToast 
        ref={ref} 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave} 
        style={style as CSSProperties}
        type={type}
      >
        <ProgressBar duration={4200} type={type} />
        <Title>
          { 
            icon !== undefined
            ? icon
            : type === "success" 
              ? <CheckmarkCircleIcon color="#30a75f" width="32px" />
              : type === "danger" 
                ? <AddCircleIcon transform="rotate(45)" color="red" width="32px" />
                : type === "warning" 
                  ? <WarningIcon color="orange" width="32px" />
                  : <ErrorIcon color="white" width="32px" /> 
          }
          <TitleText>{title}</TitleText>
          <CloseButton onClick={handleRemove}>
            <CloseIcon width="20px" color="white" />
          </CloseButton>
        </Title>
        <Description>{description}</Description>
      </StyledToast>
    </CSSTransition>
  );
};

import { Currency } from '@pancakeswap/sdk'
import { styled } from 'styled-components'
import CurrencyLogo from './CurrencyLogo'

/* const Wrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ margin }) => margin && '4px'};
` */
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface TripleCurrencyLogoProps {
  margin?: number
  size?: number
  currency0?: Currency
  currency1?: Currency
  currency2?: Currency
}

export default function TripleCurrencyLogo({
  currency0,
  currency1,
  currency2,
  size = 20,
  margin = 4,
}: TripleCurrencyLogoProps) {
  return (
    <Wrapper /* margin={margin} */>
      {currency0 && <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />}
      {currency1 && <CurrencyLogo currency={currency1} size={`${size.toString()}px`} style={{ marginLeft: `-${margin}px` }} />}
      {currency2 && <CurrencyLogo currency={currency2} size={`${size.toString()}px`} style={{ marginLeft: `-${margin}px` }} />}
    </Wrapper>
  )
}
